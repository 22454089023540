@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
/* ------------------------------------ *\
  #NavbarFloating
\* ------------------------------------ */

$nav-radius: 16px 16px 0 0;
$nav-margin: 24px;

.container {
  width: 100%;

  position: fixed;

  bottom: 0;
  right: 0;

  @media (min-width: $desktop-min-breakpoint) {
    display: none;
  }

  @media (max-width: $mobile-max-breakpoint) {
    min-width: auto;
  }

  border-radius: $nav-radius;

  pointer-events: none;
  overflow: hidden;
  z-index: 5;
  opacity: 1;
  visibility: visible;
}

.controls {
  pointer-events: auto;
  padding: 8px 10px;
  background-color: var(--white);
  border-radius: $nav-radius;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.25);

  .navigate {
    margin-right: var(--spacing-base);
    flex-grow: 1;
    max-width: 250px;
  }

  .buy {
    --color-primary: var(--page-theme-background, var(--black));
    --color-background: var(--page-theme-primary, var(--white));
  }

  &__open {
    box-shadow: none;
  }
}

.navMenu {
  pointer-events: auto;

  background-color: var(--white);

  border-radius: $nav-radius;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.25);

  padding: $nav-margin;

  position: relative;
  top: 60px;

  visibility: hidden;
  transform: translateY(100%);
  transition: all 0.3s var(--transiton-timing-ease);
  opacity: 0;

  &__open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

.navMenu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;

  display: flex;
  flex-direction: column-reverse;

  // this is inverted since list order is reverse
  li:not(:first-child) {
    margin-bottom: 24px;
  }

  li:first-child {
    margin-bottom: 8px;
  }
}

.navMenu {
  .link {
    text-decoration: none;

    &:hover {
      border-bottom: 2px solid black;
      padding-bottom: 2px;
    }
  }

  .currentLink {
    border-bottom: 2px solid black;
    padding-bottom: 2px;
    cursor: default;
  }
}

.navMenu {
  .close {
    position: absolute;
    right: $nav-margin;
  }

  .menuBuy {
    position: absolute;
    right: $nav-margin;
    bottom: $nav-margin;

    --color-primary: var(--page-theme-background, var(--black));
    --color-background: var(--page-theme-primary, var(--white));
  }
}

.toastMessage {
  @media (min-width: $tablet-min-breakpoint) {
    margin: var(--spacing-xs) 0;
  }
}

// Mobile adjustments
@media (max-width: $mobile-max-breakpoint) {
  .container {
    padding: 0;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    border-radius: 0;
    min-width: unset;
    width: 100%;

    &.hidden {
      transform: translate(-50%, -30px);
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .navMenu {
    border-radius: $nav-radius $nav-radius 0 0;
    box-shadow: none;
    bottom: 0;
    width: 100%;

    .menuBuy {
      right: 24px;
      bottom: 32px;
    }

    ul li:first-child {
      margin-bottom: 18px;
    }
  }

  .controls {
    @media (max-width: 375px) {
      button {
        font-size: 0.85em;
      }
    }
  }
}

@media (max-width: 400px) {
  .controls {
    margin-left: auto;
    margin-right: auto;
  }
}

// Menu does not fit well below 380px screen width - fixes
@media (max-width: 349px) {
  .controls button {
    padding: 8px 18px;
  }
  .controls .navigate {
    margin-right: 10px;
  }

  .menuBuy {
    padding: 10px 20px;
  }
  .navMenu li a,
  .navMenu li span {
    font-size: 16px;
  }
}
